<template>
    <div class="pc_conten">
        <Header></Header>
        <div class="main-content">
            <Left :deviceList="deviceList" :deviceManagement="deviceManagement" :mapAllDeciceList="mapAllDeciceList"></Left>
            <Right></Right>
        </div>
    </div>
</template>

<script setup>
    import { ref,  onUnmounted } from 'vue'
    import Header from "../header/index"
    import Left from "../left/index"
    import Right from "../right/index"
    import { getListDevices, getProductInfo } from "@/api/device"
    import { getWebSocket } from "@/utils/websocket"
    import { useStore } from "vuex";
    import { ElMessage } from 'element-plus'

    const store = useStore();
    const statusRef = ref("") // 状态socket
    const propertiesRef = ref({}) // 物模型socket

    const mapAllDeciceList = ref({})

    const deviceList = ref([])

    const deviceManagement = ref({})

    const guid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const getRroperties = (productId, deviceId) => {
        if (propertiesRef.value[deviceId]) {
            propertiesRef.value[deviceId].unsubscribe();
        } 
        propertiesRef.value[deviceId] = getWebSocket(
            `properties-${guid()}`,
            `/dashboard/device/${productId}/properties/realTime`,
            {
                "deviceId":  deviceId,
                "history": 1
            },
        )  
        .subscribe((res) => {
           
            let payload = res.payload?.value
            if (!deviceManagement.value[deviceId]) {
                deviceManagement.value[deviceId] = {}
            }

            console.log("payload.property----", payload.property)
            if (payload.property === 'DeviceInfo') {
                // $t('device.sheBXX')
                deviceManagement.value[deviceId]["deviceInfo"] = payload.value
            } else if (payload.property === 'DeviceSettings') {
                deviceManagement.value[deviceId]['deviceSettings'] = payload.value
            } else if (payload.property === 'LockSettings') {
                // deviceManagement.value.LockSettings = payload.value
                deviceManagement.value[deviceId]['LockSettings'] = payload.value
                // LockSettings.value = payload.value
            } else if (payload.property === 'AlarmSettings') {
                // deviceManagement.value.AlarmSettings = payload.value
                deviceManagement.value[deviceId]['alarmSettings'] = payload.value
                console.log("deviceManagement.value.alarmSettings", deviceManagement.value[deviceId]['alarmSettings'])
            } else if (payload.property === 'DeviceTime') {
                // $t('device.fangJCSZ')
                // console.log('DeviceTime', DeviceTime)
                // DeviceTime.value = payload.value
            } else if(payload.property === 'WarningInfo') {
                deviceManagement.value[deviceId]['warningInfo'] = payload.value
                console.log("deviceManagement.value[deviceId]['warningInfo'] = payload.value", deviceId, deviceManagement.value[deviceId]['warningInfo'])
            } else if (payload.property === 'LastOpen') {
                // 最后打开时间 时间戳
                deviceManagement.value[deviceId]['lastOpen'] = payload.value
            } else if (payload.property === 'RSSI') {
                deviceManagement.value[deviceId]['rssi'] = payload.value
            } else {
                //
            }

            store.commit("setDeviceManagement", deviceManagement.value)
        });
    };

    const getStatusAll = (List = []) => {
        if (List.length == 0) {
            return 
        }
        if (statusRef.value) {
            statusRef.value && statusRef.value.unsubscribe();
        } 
        statusRef.value = getWebSocket(
            `instance-list-page-${guid()}`,
            `/dashboard/device/status/change/realTime`,
            {
                deviceIds: List,
            },
        ).subscribe((res) => {
            console.log('deviec', res)
            let value = res.payload?.value || {type: 'offline'}
            let statu = value.type

            mapAllDeciceList.value[value.deviceId] = {
                value: statu,
                text: statu === 'offline'? '离线': statu === 'notActive' ? '禁用' :'在线'
            }
            store.commit("setMapAllDeciceList", mapAllDeciceList.value)
        });
    };

    // 根据设备id获取产品信息
    const getProductInfoM = (deviceId) => {
        getProductInfo(deviceId).then(r => {
            console.log("4444444444444-----------", r.result)
            store.commit("setProductInfo", {deviceId, result: r.result})
        })
    }

    const getListDevicesM = () => {
        getListDevices().then(res => {
            deviceList.value = res.result || [];
            store.commit("setDeviceRow",  deviceList.value[0] || {})
            if (deviceList.value.length == 0) {
                return  ElMessage({
                    message: `No safe is available. You could add safes through ViSN APP.`,
                    type: 'warning',
                    plain: true})   
            }
            deviceList.value.forEach(deviceItem => {
                mapAllDeciceList.value[deviceItem.deviceId] = deviceItem.state
                getRroperties(deviceItem.productId, deviceItem.deviceId)
                getProductInfoM(deviceItem.deviceId)
            });
            getStatusAll(Object.keys(mapAllDeciceList.value))
            store.commit("setMapAllDeciceList", mapAllDeciceList.value)
        })
    }

    getListDevicesM()

    onUnmounted(() => {
     statusRef.value && statusRef.value.unsubscribe();
     Object.keys(propertiesRef.value).forEach(id => {
        propertiesRef.value[id] && propertiesRef.value[id].unsubscribe();
     })
    });
</script>

<style lang="scss" scoped>
.pc_conten {
    padding-top: 10px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    .main-content {
        display: flex;
        flex: 1;
        height: 0;
        overflow-y: hidden;
    }
}

</style>
<template>
  <!-- <router-view/> -->
   <div style="height: 100%;">
      <Login v-if="!isLogin"></Login>
      <Home v-else></Home>
   </div>
</template>

<script setup>
import Home from "./page/home/index.vue"
import Login from "./page/login/index.vue"
import { ref, computed, onMounted, onBeforeUnmount } from "vue"
import { useStore } from "vuex";



const store = useStore();

onMounted(() => {  
      window.addEventListener('beforeunload', handleBeforeUnload);  
    });  

    onBeforeUnmount(() => {  
      window.removeEventListener('beforeunload', handleBeforeUnload);  
    });  

const handleBeforeUnload = (event) => {  
    // 存下当前时间
    let currentTime = new Date().getTime()
    localStorage.setItem("currentTime", currentTime)
  };    


const isLogin = computed(() => {
  return store.state.isLogin
})

</script>

<style>
#app {
  color: #fff;
  height: 100%;
  width: 100%;
}


</style>

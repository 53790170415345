<template>
    <div class="right_conten">
        <router-view  :key="deviceRow.deviceId"/>
    </div>
</template>

<script setup lang="js">
import { ref, defineProps, computed } from 'vue'
import { useStore } from "vuex";
const store = useStore();

const deviceRow = computed(() =>{
    return store.state.deviceRow
})

</script>

<style lang="scss" scoped>
.right_conten {
    width: 100%;
    height: 100%;
    background-color: #1a1a1a;
    box-sizing: border-box;
    // padding: 20px 60px;
    overflow-y: auto;
}
</style>
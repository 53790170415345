<template>
    <div class="sidebar">
            <span class="networkName" id="networkName">{{firstName}}'s Safe Network</span>
            <div class="sidebarColumn">
                <div :class="{'alertRed': (warningList[item.deviceId] && warningList[item.deviceId].length>0) 
                || mapAllDeciceList[item.deviceId].value == 'offline'
                || (deviceManagement[item.deviceId] && deviceManagement[item.deviceId].deviceInfo && deviceManagement[item.deviceId].deviceInfo.Door == 2)}" class="sidebarSafeDivs" 
                    v-for="item in deviceList" :key="item.deviceId"
                    @click="selctItem(item)"
                    id="safe1">
                        <template v-if="deviceManagement[item.deviceId] 
                        && deviceManagement[item.deviceId].deviceInfo
                        && deviceManagement[item.deviceId].alarmSettings"
                        >
                            <span class="safeName">{{item.deviceName}}</span>
                            <div class="lastOpenedTextDiv">
                                <span class="lastOpenedText">Last Opened:</span>
                                <span class="lastOpenedDate" id="lastOpened" v-if="deviceManagement[item.deviceId].lastOpen">
                                    {{timeSwicth(item.deviceId)}}</span>
                            </div>
                            <div class="sidebarImgDiv dis_f">
                                <template v-if="mapAllDeciceList[item.deviceId].value == 'offline'">
                                    <img class="sidebarSafeImg" 
                                    :src="item.photoUrl" alt="Safe">
                                </template>
                                <template v-else>
                                    <img v-if="deviceManagement[item.deviceId].deviceInfo.Door == 2 && productInfo[item.deviceId]" class="sidebarSafeImg" 
                                    :src="productInfo[item.deviceId].configuration.guidePhotoUrl" alt="Safe">
                                    <img v-if="deviceManagement[item.deviceId].deviceInfo.Door == 1" class="sidebarSafeImg" 
                                    :src="item.photoUrl" alt="Safe">
                                </template>
                                <template v-if="mapAllDeciceList[item.deviceId].value == 'offline'">
                                    <div class="alarm_Icon">
                                        <img class="sidebarStatusImg"
                                        src="../../assets/img/ic_main_warning.png" alt="Safe"> 
                                        <span>Offline</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="alarm_Icon" v-if=" warningList[item.deviceId] && warningList[item.deviceId].length>0">
                                        <template v-for="(warnItem, $i) in warningList[item.deviceId]" :key="$i">
                                            <template v-if="warnIndexObj[item.deviceId] == $i || (!warnIndexObj[item.deviceId] && $i == 0)">
                                                <img v-if="item.state != 'Open'" class="sidebarStatusImg"
                                                src="../../assets/img/ic_main_warning.png" alt="Safe"> 
                                                <img v-else class="sidebarStatusImg"
                                                src="../../assets/img/ic_main_unlock.jpg" alt="Safe"> 
                                                <div class="span_dom" style="color: #000;font-size: 15px;">{{ warnItem.state }}</div>
                                            </template>
                                        </template> 
                                    </div>
                                    <div class="alarm_Icon" v-else>
                                        <img v-if="deviceManagement[item.deviceId].deviceInfo.Door == 1" class="sidebarStatusImg"
                                        src="../../assets/img/ic_main_lock.jpg" alt="Safe">
                                        <img v-if="deviceManagement[item.deviceId].deviceInfo.Door == 2" class="sidebarStatusImg"
                                        src="../../assets/img/ic_main_unlock.jpg" alt="Safe"> 
                                        <span>{{deviceManagement[item.deviceId].deviceInfo.Door == 1 ? "Locked" : "Open"}}</span>
                                    </div>
                                </template>
                            </div>
                        </template>
                    </div>
                <!-- <div class="sidebarSafeDivs alertRed" style="border-bottom: 1px ridge #535353;" id="safe3" @click="selctItem(2)">
                    <span class="safeName">{{firstName}}'s NSL2i</span>
                    <div class="lastOpenedTextDiv">
                        <span class="lastOpenedText">Last Opened:</span>
                        <span class="lastOpenedDate" id="lastOpened">Aug 09, 2024 4:24 PM</span>
                    </div>
                    <div class="sidebarImgDiv">
                        <img class="sidebarSafeImg" src="../../assets/img/sliderClosed.png" alt="Safe">
                        <img class="sidebarStatusImg" src="../../assets/img/LockedIcon.png" alt="Safe">
                    </div>
                </div> -->
            </div>
        </div>
</template>


<script setup>
 import { ref, computed, defineProps, onUnmounted, watchEffect } from 'vue'
 import { useStore } from "vuex";
 import { useRouter } from 'vue-router'
 const router = useRouter()

 let warnTime = null 
 const store = useStore();
 const props = defineProps({
    deviceList: {
        type: Array,
        default: () => [],
    },
});

 const warnIndexObj = ref({

 })

 const items = ref([])

 onUnmounted(() => {
    if (warnTime) {
        clearInterval(warnTime)
        warnTime = null
    }
})

 const deviceManagement = computed(() => {
    // console.log("deviceManagement", store.state.deviceManagement)
  return store.state.deviceManagement || {}
 })

 const mapAllDeciceList = computed(() => {
  return store.state.mapAllDeciceList || {}
 })

 const productInfo = computed(() => {
    console.log("productInfo", store.state.productInfo)
  return store.state.productInfo || {}
 })

 const firstName = computed(() =>{
    return store.state.userInfos.user.firstName
 })

 const warningList = computed(() =>{
    let arrInfo = {}
    Object.keys(deviceManagement.value).forEach(deviceId => {
        arrInfo[deviceId] = []
        Object.keys(deviceManagement.value[deviceId].warningInfo || {}).forEach(key => {
            if (deviceManagement.value[deviceId].warningInfo[key] == 2) {
                // 为2告警
                let warnObj = {
                    "TamperPINCode": {
                        state: "Tampering\nDetected",
                        info: "KEYPAD TAMPERING DETECTED"
                    },
                    "AlarmLowBattery": {
                        state: "Low Battery",
                        info: "LOW BATTERY"
                    },
                    "TamperMobileAPP": {
                        state: "TamperMobileAPP",
                        info: "TamperMobileAPP DETECTED"
                    },
                    "AlarmUnauthorizedOpen": {
                        state: (deviceManagement.value[deviceId].deviceInfo &&
                         deviceManagement.value[deviceId].deviceInfo.Door == 1 )?  "Locked" : "Open",
                        info: "UNAUTHORIZED ACCESS DETECTED"
                    },
                    "AlarmSternWarning": {
                        state: "AlarmSternWarning",
                        info: "AlarmSternWarning DETECTED"
                    },
                    "AlarmMotion": {
                        state: "Impact\nDetected",
                        info: "IMPACT DETECTED ON SAFE"
                    },
                    "TamperSmartKey": {
                        state: "TamperSmartKey",
                        info: "NANO KEY TEMPERING DETECTED"
                    },
                    "AlarmDoorUnclosed": {
                        state: "Open",
                        info: "ALERT OF DOOR OPEN"
                    },
                    "TamperRFID": {
                        state: "TamperRFID",
                        info: "TamperRFID DETECTED" 
                    },
                    "AlarmForceOpen": {
                        state: "Force Open",
                        info: "FORCE OPEN DETECTED" 
                    },
                    "TamperKey": {
                        state: "TamperKey",
                        info: "TamperKey DETECTED" 
                    },
                    "AlarmTemperature": {
                        state: "Temperature\n Exceeded",
                        info: "TEMPERATURE HAS EXCEEDED SET RANGE" 
                    },
                    "TamperFace": {
                        state: "TamperFace",
                        info: "TamperFace DETECTED" 
                    },
                    "TamperFP": {
                        state: "Tampering\n Detected",
                        info: "BIOMETRIC TAMPERING DETECTED"
                    },
                    "TamperNFC": {
                        state: "TamperNFC",
                        info: "TamperNFC DETECTED" 
                    },
                    "AlarmHumidity": {
                        state: "Humidity\n Exceeded",
                        info: "HUMIDITY HAS EXCEEDED SET RANGE"
                    },
                }
                arrInfo[deviceId].push(warnObj[key] || {})
            }
        })
         //  是不是开门 开门也需要告警
        if (deviceManagement.value[deviceId].deviceInfo && deviceManagement.value[deviceId].deviceInfo.Door == 2) {
            arrInfo[deviceId].push({   
                state: "Open",
                info: "SAFE DOOR IS OPEN"
            })
        }
    })
    console.log("arrInfo", arrInfo)
    return arrInfo
 })


 const timeSwicth = (deviceId) => {
    // Aug 09, 2024 4:24 PM
    if (deviceManagement.value[deviceId]) {
        let timestamp = deviceManagement.value[deviceId].lastOpen || 0
        const date = new Date(timestamp);  
        const options = {  
            year: 'numeric',  
            month: 'short',  
            day: 'numeric',  
            hour: 'numeric',  
            minute: 'numeric',  
            hour12: true ,
            timeZone: 'GMT' // 设置为 GMT 的时区   

        };  
        return date.toLocaleString('en-US', options);   
    } else {
        return ""
    }
 }

 const selctItem = (item) => {
    store.commit("setDeviceRow", item)
    router.push({
        path: "/"
    })
 }


 // 告警切换
 const warnSwicth = () => {
    console.log("来了-----------------")
    if (warnTime) {
        clearInterval(warnTime)
        warnTime = null
    } 
    warnTime = setInterval(() => {
        Object.keys(warningList.value).forEach(deviceId => {
           if(!warnIndexObj.value[deviceId] && warnIndexObj.value[deviceId] !== 0) {
                warnIndexObj.value[deviceId] = 0
           } else {
                let len = warningList.value[deviceId].length
                warnIndexObj.value[deviceId]++
                if (warnIndexObj.value[deviceId] >= len) {
                    warnIndexObj.value[deviceId] = 0
                }
           }
        })
    }, 2000)
 }


 watchEffect(() => {
    warnSwicth()
})

 console.log(items)
</script>


<style lang="scss" scoped>
.sidebar {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
}
.alarm_Icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 40px;
    background-color: #d3d3d3;
    padding: 4px 15px 4px 4px;
    box-sizing: border-box;
    width: 180px;
    &>img {
        margin-right: 8px;
        width: 45px;
        height: 45px;
    }
    &>span {
        color: #000;
        font-size: 14px;
        display: inline-block;
        width: 100px;
    }
}
.sidebarImgDiv {
    :deep(.el-carousel__container) {
        width: 180px;
    }
}
.sidebarSafeDivs {
    cursor: pointer;
}
.sidebarSafeImg {
    width: 164px;
}
</style>
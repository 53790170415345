
import { createStore } from 'vuex'
import { getToken, LocalStore } from '@/utils/comm'
export default createStore({
  state: {
    isLogin: getToken(),
    userInfos: LocalStore.get('userInfo') || {
      user: {}
    },
    deviceManagement: {},
    mapAllDeciceList: {},
    deviceRow: {
      deviceId: ""
    },
    productInfo: {

    }
  },
  mutations: {
    setProductInfo(state, obj) {
      let {deviceId, result} = obj
      state.productInfo[deviceId] = result
    },
    setIsLogin(state, falg = false) {
      state.isLogin = falg
    },
    setUserInfos(state, userInfo) {
      state.userInfos = userInfo
      LocalStore.set('userInfo', userInfo)
    },
    setUserName(state, userName) {
      state.userInfos.user.firstName = userName
      LocalStore.set('userInfo', state.userInfos)
    },
    setLastName(state, lastName) {
      console.log("******", lastName)
      state.userInfos.user.lastName = lastName
      LocalStore.set('userInfo', state.userInfos)
    },
    setDeviceManagement(state, deviceManagement) {
      state.deviceManagement = deviceManagement
    },
    setMapAllDeciceList(state, mapAllDeciceList) {
      state.mapAllDeciceList = mapAllDeciceList
    },
    setDeviceRow(state, device) {
      state.deviceRow = device
    },
  },
  actions: {
  
  },
  modules: {

  }
})

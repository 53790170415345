import axios from 'axios'
// import { notification as Notification, Modal } from 'jetlinkscky-ui-components'
import router from '@/router'
import { cleanToken, getToken, md5Js } from '@/utils/comm'
import { ElNotification, ElMessage } from 'element-plus'
import store from "@/store/index";
import { ElLoading } from 'element-plus'



const TOKEN_KEY = "X-Access-Token"
const LoginPath = "/" // "/login"
let loadingInstance = null

export const SUCCESS_CODE = 200 // 成功代码

export const request = axios.create({
  withCredentials: false,
  baseURL: "/api",
  timeout: 1000 * 15
})

/**
 * POST method request
 * @param {String} url
 * @param {Object} [data]
 * @param {String} responseType 如果接口是需要导出文件流，那么responseType = 'blob'
 * @param {Object|String} [ext] 扩展参数，如果是配置headers，ext对象内包含headers对象，如下
 * {
        headers: {'Content-Type': 'text/plain;charset=UTF-8'},
    }
 * @returns {AxiosInstance}
 */
export const post = function (url, data = {}, params = {}, ext = {}) {
  ext = typeof ext === 'string' ? { responseType: ext } : ext
  return request({
    ...ext,
    params,
    method: 'POST',
    url,
    data
  })
}

/**
 * put method request
 * @param {String} url
 * @param {Object} [data]
 * @returns {AxiosInstance}
 */
export const put = function (url, data = {}) {
  return request({
    method: 'PUT',
    url,
    data
  })
}

/**
 * patch method request
 * @param {String} url
 * @param {Object} [data]
 * @returns {AxiosInstance}
 */
export const patch = function (url, data = {}, ext = {}) {
  return request({
    method: 'PATCH',
    url,
    data,
    ...ext
  })
}
/**
 * GET method request
 * @param {String} url
 * @param {Object} [params]
 * @param {Object} [ext] 扩展参数
 * @returns {AxiosInstance}
 */
export const get = function (url, params = {}, ext = {}) {
  return request({
    method: 'GET',
    url,
    params,
    ...ext
  })
}

/**
 * DELETE method request
 * @param {String} url
 * @param {Object} [params]
 * @param {Object} [ext] 扩展参数
 * @returns {AxiosInstance}
 */
export const remove = function (url, params = {}, ext = {}) {
  return request({
    method: 'DELETE',
    url,
    params,
    ...ext
  })
}

/**
 * 获取文件流
 * @param {String} url
 * @param {Object} [params]
 * @return {*}
 */
export const getStream = function(url, params = {}) {
  return get(url, params, {
    responseType: 'arraybuffer' // 设置请求数据类型，返回blob可解析类型
  })
}

export const getStream1 = function(url, data = {}) {
  return post(url, data, {}, {
    responseType: 'blob' // 设置请求数据类型，返回blob可解析类型
  })
}

export const postStream = function(url, data = {}, params = {}) {
  return post(url, data, params, {
    responseType: 'arraybuffer' // 设置请求数据类型，返回blob可解析类型
  })
}

const showNotification = (message, description, key, show = true) => {
  if (show) {
    // ElNotification({
    //   title: "",//message,
    //   message: description,
    //   type: 'error',
    // })

    ElMessage({
      message: description,
      type: 'warning',
      plain: true}) 
  }
}

/**
 * 异常拦截处理器
 * @param {Object} error
 * @returns {Promise<never>}
 */
const errorHandler = (error) => {
  if (loadingInstance) {
    loadingInstance.close()
  }
  if (error.response) {
    const data = error.response.data
    const status = error.response.status
    if (data?.code === 'license required') {
    //  Modal.error({
    //     key: 'License',
    //     title: 'License已到期或者错误',
    //     content: h(
    //       'a',
    //       {
    //         onClick: () =>
    //         {
    //           Modal.destroyAll?.();
    //           window.location.href = '/#/init-license';
    //         }
    //       },
    //       '请更新License'
    //     )
    //   })
    }
    //  else if (status === 403) {
    //   showNotification('Forbidden', (data.message + '').substr(0, 90), '403')
    // } else if (status === 500) {
      else if (status === 500) {
        showNotification('Tips', (data.message + '').substr(0, 90), '500')
      }
       else if (status === 400) {
       showNotification('Tips', (data.message + '').substr(0, 90), '400')
     } 
    else if (status === 401) {
      // showNotification('Unauthorized', 'User not logged in', '401')
      setTimeout(() => {
        cleanToken()
        console.log("-------store-----", store)
        store.commit("setIsLogin", false)
        router.replace({
          path: LoginPath
        })
      }, 0)
    } else if (status === 404) {
      const data = error?.response?.data
      const message = error?.response?.data?.message || `${data?.error} ${data?.path}`
      showNotification(error?.code, message, '404')
    }
  } else if (error.response === undefined) {
    if (error.message.includes('timeout')) {
      showNotification(error.message, 'Request TimeOut！', undefined)
    } else {
      showNotification(error.message, (error.stack + '').substr(0, 90), undefined)
    }
  }
  return Promise.reject(error)
}

const generateRandomString = (length) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// 请求头里添加参数
const addheader = () => {
  let nonce = generateRandomString(10)
  let timestamp = new Date().getTime()
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  let jmStr = `${timezone}|${timestamp}|${nonce}`
  let sign = md5Js(jmStr)
  let obj = {
    nonce, // 10位随机数 大小写字母数字
    timestamp,
    timezone, // 客户端时区
    sign // 签名md5(timezone|timestamp|nonce)
  }

  return obj
}


// request interceptor
request.interceptors.request.use(config => {
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (loadingInstance) {
    loadingInstance.close()
  }
  if (!config.isHideLoading) {
    loadingInstance = ElLoading.service({ fullscreen: true, text: "Loding", background:"rgba(0, 0, 0, 0.6)" })
  }

  let headerObj = addheader()
  
  Object.keys(headerObj).forEach(key => {
    config.headers[key] = headerObj[key]
  })
  
  const token = getToken()
  console.log("token", token)
  if (!token) {
    setTimeout(() => {
      cleanToken()
      router.replace({
        path: LoginPath
      })
    }, 0)
    return config
  }
 
  config.headers[TOKEN_KEY] = token

  return config
}, errorHandler)

/**
 * response interceptor
 */
request.interceptors.response.use(response => {
  if (loadingInstance) {
    loadingInstance.close()
    loadingInstance = null
  }
  if (response.data instanceof ArrayBuffer) {
    return response
  } else {
    const { status } = response.data
    // 增加业务接口处理成功判断方式，只需要判断返回参数包含：success为true
    if (typeof response.data === 'object' && typeof response.data.success === 'undefined') {
      response.data.success = status === SUCCESS_CODE
    }

    // 如果返回的的是文件流，那么return值则为response
    if (response.headers['content-type'] === 'application/octet-stream; charset=UTF-8' || response.headers['content-type'] === 'application/vnd.ms-excel;charset=UTF-8') {
      return response.data
    } else {
      return response.data
    }
  }
}, errorHandler)

export default {
  request,
  post,
  get,
  patch,
  put,
  remove,
  getStream,
  getStream1,
  postStream
}
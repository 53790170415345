import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './css/style2.css'
import { cleanToken } from '@/utils/comm'

const checkTime = () => {
    let currentTime = new Date().getTime()
    let oldCurrentTime = Number(localStorage.getItem("currentTime")) || 0
    console.log("时间差值", (currentTime - oldCurrentTime)/1000)
    if (currentTime - oldCurrentTime > 3000) {
        // 大于3秒认为重新进来清空token
        cleanToken()
        store.commit("setIsLogin", false)
    }
}
checkTime()

createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
